import { secondary } from "./colors";
import { Queens } from "./import-fonts";

export const FONT_GENERAL_SANS = "var(--general-sans)";
export const FONT_QUEENS = "var(--queens-medium)";
export const DEFAULT_FONT_SIZE = 16;

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

/**
 * Base = 16px
 **/
export const fontSize = {
  xs: ".8rem", // 12px
  s: ".9rem", // 14px
  m: "1rem", // 16px
  l: "1.25rem", // 20px
  xl: "1.5rem", // 24px
  xxl: "3rem", // 48px
};

export const defaultTypography = {
  title: {
    fontFamily: Queens.style.fontFamily,
    fontSize: fontSize.xxl,
    color: secondary[130],
  },
  h1: {
    fontSize: fontSize.xxl,
    fontWeight: fontWeight.medium,
  },
  h2: {
    fontSize: fontSize.xxl,
    fontWeight: fontWeight.medium,
  },
  h3: {
    fontSize: fontSize.xl,
    fontWeight: fontWeight.medium,
    color: secondary[130],

    "@media (max-width: 600px)": {
      fontSize: fontSize.m,
    },
  },
  h4: {
    fontSize: fontSize.l,
    fontWeight: fontWeight.medium,
    lineHeight: "140%",
    color: secondary[130],
  },
  h5: {
    fontSize: fontSize.m,
    lineHeight: 1.2,
    fontWeight: fontWeight.medium,
  },
  h6: {
    fontSize: fontSize.s,
    lineHeight: 1.2,
  },
  button: {
    fontSize: fontSize.m,
    lineHeight: 1.4,
    fontWeight: fontWeight.regular,
    textTransform: "none",
  },
  caption: {
    fontSize: fontSize.xs,
    textTransform: "uppercase",
    color: secondary[70],
    letterSpacing: "0.05em",
  },
  subtitle1: {
    fontWeight: fontWeight.medium,
    fontSize: fontSize.l,
    color: secondary[130],
    lineHeight: 1.5,

    "@media (max-width: 600px)": {
      fontSize: fontSize.m,
    },
  },
  subtitle2: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize.l,
    color: secondary[130],
    lineHeight: 1.5,
  },
  body1: {
    fontSize: fontSize.m,
    color: secondary[150],
    lineHeight: "140%",

    "@media (max-width: 600px)": {
      fontSize: fontSize.s,
    },
  },
  body2: {
    fontWeight: fontWeight.regular,
    fontSize: fontSize.xs,
    lineHeight: 1.5,
    color: secondary[130],
  },
};
