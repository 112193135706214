import type { NextPage } from "next";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useReportWebVitals } from "next/web-vitals";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import awsExports from "@/aws-exports.js";
import "@/common/styles/globals.css";
import { GeneralSans, Queens } from "@/common/styles/import-fonts";
import { assembleTheme } from "@/common/styles/theme";
import { AppContextProvider } from "@context/AppContext";
import Hotjar from "@hotjar/browser";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Amplify } from "aws-amplify";
import { GoogleAnalytics, event } from "nextjs-google-analytics";
import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";

import Analytics from "@components/analytics";
import ErrorBoundary from "@components/common/ErrorBoundary";
import GlobalPopup from "@components/common/GlobalPopup";
import Layout from "@components/layout/Layout";

import { store } from "@redux/store";

const siteId = process.env.NEXT_PUBLIC_HOTJAR_ID;
const hotjarVersion = process.env.NEXT_PUBLIC_HOTJAR_VERSION;

const ToasterNotification = dynamic(() => import("@components/common/ToasterNotification"), {
  ssr: false,
});

export type NextPageCustomLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageCustomLayout;
};

Amplify.configure({ ...awsExports });

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  useReportWebVitals(({ name, label, value, id }) => {
    event(name, {
      category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      value: Math.round(name === "CLS" ? value * 1000 : value),
      label: id,
      nonInteraction: true,
    });
  });

  useEffect(() => {
    if (siteId) {
      Hotjar.init(Number(siteId), Number(hotjarVersion));
    }
  }, []);

  const getLayout = Component.getLayout ?? (page => <Layout>{page}</Layout>);

  return (
    <SessionProvider session={session} refetchOnWindowFocus>
      <ThemeProvider theme={assembleTheme}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <AppContextProvider>
                <ErrorBoundary>
                  <GoogleAnalytics trackPageViews />
                  <Analytics />
                  <style jsx global>{`
                    :root {
                      --general-sans: ${GeneralSans.style.fontFamily};
                      --queens-medium: ${Queens.style.fontFamily};
                    }
                  `}</style>
                  {getLayout(<Component key={router.asPath} {...pageProps} />)}
                  <ToasterNotification />
                  <GlobalPopup />
                </ErrorBoundary>
              </AppContextProvider>
            </Hydrate>
          </QueryClientProvider>
        </Provider>
      </ThemeProvider>
    </SessionProvider>
  );
}

export default App;
