import { useRouter } from "next/router";

import { useAppContext } from "@context/AppContext";

import { getLoggedInMenu } from "@configs/features/menus";

import useAuthSession from "@hooks/useAuthSession";

import Menu from "../MenuLink";

import { SNavbar } from "./styles";

const Navbar = () => {
  const { hasAssembleU } = useAuthSession();
  const { isMobile } = useAppContext();
  const { pathname } = useRouter();
  const menu = getLoggedInMenu(hasAssembleU);

  if (isMobile || pathname === "/") {
    return null;
  }

  return (
    <SNavbar>
      {menu.map(item => (
        <Menu
          key={item.path}
          href={item.path}
          active={pathname === item.path}
          icon={item.icon}
          onClick={item.onClick}
        >
          {item.name}
        </Menu>
      ))}
    </SNavbar>
  );
};

export default Navbar;
