export const primary = {
  100: "#FC6D1D",
  90: "#FC7C34",
  80: "#FD8A4A",
  70: "#FD9961",
  60: "#FDA777",
  50: "#FDB68E",
  40: "#FEC5A5",
  30: "#FED3BB",
  20: "#FEE2D2",
  10: "#FFF0E8",
  5: "#FFF8F4",
  150: "#7E370F",
  140: "#974111",
  130: "#B04C14",
  120: "#CA5717",
  110: "#E3621A",
};

export const secondary = {
  100: "#6F5545",
  90: "#7D6658",
  80: "#8C776A",
  70: "#9A887D",
  60: "#A9998F",
  50: "#B7AAA2",
  40: "#C5BBB5",
  30: "#D4CCC7",
  20: "#E2DDDA",
  10: "#F1EEEC",
  5: "#F8F7F6",
  150: "#382B23",
  140: "#433329",
  130: "#4E3B30",
  120: "#594437",
  110: "#644C3E",
  2: "#FBFBFB",
};

export const tertiary = {
  100: "#FFAE12",
  90: "#FFB62A",
  80: "#FFBE41",
  70: "#FFC659",
  60: "#FFCE71",
  50: "#F8D893",
  40: "#FFDFA0",
  30: "#FFE7B8",
  20: "#FFEFD0",
  10: "#FFF7E7",
  5: "#FFFBF3",
  150: "#BE6700",
  140: "#CF7305",
  130: "#D87C0E",
  120: "#E58C10",
  110: "#F29D11",
};

export const white = {
  100: "rgba(255, 255, 255, 1)",
  90: "rgba(255, 255, 255, 0.9)",
  80: "rgba(255, 255, 255, 0.8)",
  70: "rgba(255, 255, 255, 0.7)",
  60: "rgba(255, 255, 255, 0.6)",
  50: "rgba(255, 255, 255, 0.5)",
  40: "rgba(255, 255, 255, 0.4)",
  30: "rgba(255, 255, 255, 0.3)",
  20: "rgba(255, 255, 255, 0.2)",
  10: "rgba(255, 255, 255, 0.1)",
};

export const mint = {
  100: "#79CB86",
  50: "#BCE5C2",
  40: "#C9EACF",
  30: "#D7EFDB",
  20: "#E4F5E7",
  10: "#F2FAF3",
  110: "#5DC06F",
};

export const periwinkle = {
  100: "#9CA4EE",
  50: "#CED2F6",
  40: "#D8DBF8",
  30: "#E1E4FA",
  20: "#EBEDFC",
  10: "#F5F6FD",
  110: "#747FE7",
};
