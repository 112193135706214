import Link from "next/link";

import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { secondary } from "@styles/colors";

export const SFooter = styled(Stack)<{ component: unknown }>`
  padding: ${({ theme }) => theme.spacing(5, 0, 10)};
`;

export const SNav = styled(Stack)`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(15)};

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(3)};
  }
`;

export const SNavSection = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const SLink = styled(Link)`
  color: ${secondary[130]};
  font-size: ${({ theme }) => theme.typography.pxToRem(17)};
  font-weight: 500;
  text-decoration: none;
  line-height: 140%;
  &:hover {
    text-decoration: underline;
  }
`;

export const STextButton = styled(Button)`
  color: ${secondary[130]};
  font-size: ${({ theme }) => theme.typography.pxToRem(17)};
  font-weight: 500;
  text-decoration: none;
  line-height: 140%;
  padding: 0;
  min-height: 0;
  justify-content: flex-start;

  &:hover {
    text-decoration: underline;
    background: none;
  }
`;

export const SSocialLinks = styled(Stack)`
  flex-direction: row;
  gap: 7px;
  align-self: flex-end;

  ${({ theme }) => theme.breakpoints.down("md")} {
    align-self: flex-start;
  }
`;

export const SCopyright = styled(Typography)`
  color: ${secondary[130]};
  font-size: ${({ theme }) => theme.typography.pxToRem(11)};
  font-weight: 400;
  line-height: 140%;
`;
