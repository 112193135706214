import { Skeleton, Stack } from "@mui/material";

const HeaderSkeleton = () => {
  return (
    <>
      <Skeleton
        animation="wave"
        width={650}
        height={60}
        variant="rounded"
        sx={{ borderRadius: 48 }}
      />
      <Stack direction="row" spacing={1}>
        <Skeleton animation="wave" width={60} height={60} variant="circular" />
        <Skeleton animation="wave" width={60} height={60} variant="circular" />
      </Stack>
    </>
  );
};

export default HeaderSkeleton;
