import dynamic from "next/dynamic";
import Image from "next/image";
import type { FC } from "react";

import { SEGMENT_EVENTS } from "@/common/analytics";
import { track } from "@analytics/track";

import { type AppBarProps, Button, Container, Link, Stack } from "@mui/material";

import type { LoginPopupProps } from "@components/account/Login/Popup";
import AuthUserControl from "@components/layout/AuthUserControl";

import { signinSelectors } from "@configs/features/testSelectors";

import useAuthSession from "@hooks/useAuthSession";
import usePopup from "@hooks/usePopup";

import Navbar from "../Navbar";

import HeaderSkeleton from "./Skeleton";
import { SHeader, SToolbar } from "./styles";

const LoginPopup = dynamic<LoginPopupProps>(() => import("@components/account/Login/Popup"), {
  ssr: false,
}) as FC<LoginPopupProps>;

const Header = (props: AppBarProps) => {
  const { userId, status, isLoading } = useAuthSession();
  const { handleOpenPopup } = usePopup();

  const isUserOnboarded = status === "finished";

  const handleLogin = () => {
    track(SEGMENT_EVENTS.NAVIGATION.LOGIN_BUTTON_CLICKED);
    handleOpenPopup<LoginPopupProps>({ component: LoginPopup });
  };

  const handleTrackEvent = () => {
    track(SEGMENT_EVENTS.NAVIGATION.ASSEMBLE_LOGO_CLICKED);
  };

  const navbarContent = () => {
    if (isLoading) {
      return <HeaderSkeleton />;
    }

    if (!userId) {
      return (
        <Stack direction="row" gap={1.5}>
          <Button onClick={handleLogin} data-testid={signinSelectors.loginBtn}>
            Login
          </Button>
          <Button component={Link} href="/register" variant="contained">
            Sign up
          </Button>
        </Stack>
      );
    }

    if (isUserOnboarded) {
      return (
        <>
          <Navbar />
          <AuthUserControl />
        </>
      );
    }

    return (
      <Button variant="contained" component={Link} href="/dashboard">
        Access Assemble here
      </Button>
    );
  };

  return (
    <SHeader position="static" color="inherit" {...props}>
      <Container maxWidth="xl">
        <SToolbar>
          <Stack direction="column" alignItems="center" spacing={2} width="auto">
            <Link href={userId ? "/dashboard" : "/"} onClick={handleTrackEvent}>
              <Image
                src="/images/come-assemble-logo.svg"
                priority
                alt="Logo"
                width={135}
                height={26}
              />
            </Link>
          </Stack>
          {navbarContent()}
        </SToolbar>
      </Container>
    </SHeader>
  );
};

export default Header;
