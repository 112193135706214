/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:e0046724-c498-4869-96d7-d9a02cc559c9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RX0H97r55",
    "aws_user_pools_web_client_id": "6r82n5aue2k87lqm5gni3cvvvh",
    "oauth": {
        "domain": "comeassemble-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/auth/loading/,https://v2.comeassemble.com/auth/loading/,https://comeassemble.com/auth/loading/",
        "redirectSignOut": "http://localhost:3000/,https://v2.comeassemble.com/,https://comeassemble.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AssembleLiveSessionAttendance-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "AssembleLiveSessionSlot-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "ForumComment-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "ForumCommentLike-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "ForumLike-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "Hospital",
            "region": "us-east-1"
        },
        {
            "tableName": "Notification-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "Pathway-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "PollAnswer-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "PostReview-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "User-staging",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "assemble122026-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
