import { FunctionComponent } from "react";

import { ModalActionTypes } from "@redux/global-popup/actions";
import { useAppDispatch } from "@redux/store";

type MappedFields<T> = {
  [P in keyof T]?: T[P];
};

interface OpenPopupProps<T> {
  component: FunctionComponent<T>;
  props?: MappedFields<T> & {
    onClose?: () => void;
  };
}

const usePopup = () => {
  const dispatch = useAppDispatch();

  const handleOpenPopup = <T>({ component: Component, props = {} }: OpenPopupProps<T>) => {
    const { onClose = onClosePopup, ...popupProps } = props;

    dispatch({
      type: ModalActionTypes.ShowModal,
      payload: {
        component: Component,
        props: {
          ...popupProps,
          onClose,
        },
      },
    });
  };

  const onClosePopup = () => {
    dispatch({
      type: ModalActionTypes.HideModal,
    });
  };

  return { handleOpenPopup, onClosePopup };
};

export default usePopup;
