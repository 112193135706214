import { createTheme } from "@mui/material/styles";

import { ExtendedTypographyOptions } from "@configs/modules/mui.typograph.overrides";

import { mint, primary, secondary, tertiary } from "./colors";
import { DEFAULT_FONT_SIZE, defaultTypography, fontSize } from "./fonts";
import { GeneralSans } from "./import-fonts";

export const assembleTheme = createTheme({
  palette: {
    primary: {
      light: "#FD8A4A",
      main: "#FC6D1D",
      dark: "#E3621A",
      ...primary,
    },
    secondary: {
      light: "#8C776A",
      main: "#6F5545",
      dark: "#594437",
      ...secondary,
    },
    error: {
      light: "#CA5717",
      main: "#B04C14",
      dark: "#7E370F",
    },
    warning: {
      light: "#CA5717",
      main: "#FFAE12",
      dark: "#F29D11",
    },
    info: {
      light: "#CED2F6",
      main: "#9CA4EE",
      dark: "#747FE7",
    },
    success: {
      light: "#BCE5C2",
      main: "#79CB86",
      dark: "#5DC06F",
    },
    border: {
      main: "#F0F0F0",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1390,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: GeneralSans.style.fontFamily,
    fontSize: DEFAULT_FONT_SIZE,
    pxToRem: (value: number) => `${value / DEFAULT_FONT_SIZE}rem`,
    ...defaultTypography,
  } as ExtendedTypographyOptions,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          title: "h1",
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h2",
          subtitle2: "h3",
          body1: "p",
          body2: "p",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: secondary[130],
          borderRadius: "3.3125rem",
          padding: ".5625rem 1.25rem",
          boxShadow: "none",
          fontSize: fontSize.s,
          fontWeight: 600,
          minHeight: 42,
          lineHeight: "150%",
          letterSpacing: "-0.14px",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedPrimary: {
          color: "#FFF",
          background: primary[100],
          "&:hover": {
            background: primary[110],
          },
        },
        outlinedPrimary: {
          color: primary[60],
        },
        containedSecondary: {
          color: "#FFF",
        },
        containedSuccess: {
          color: secondary[130],
          background: mint[30],
          "&:hover": {
            background: mint[40],
          },
        },
        outlinedSuccess: {
          color: tertiary[110],
        },
        containedWarning: {
          color: "#4B3C32",
          background: tertiary[30],
          "&:hover": {
            background: "#FFD688",
          },
        },
        containedError: {
          color: "#fff",
          background: primary[60],
          "&:hover": {
            background: primary[80],
          },
        },
        textSecondary: {
          color: "#000",
          "&:hover": {
            background: "rgba(0,0,0,.16)",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: secondary[150],
          color: "#fff",
          ".MuiChip-label": {
            letterSpacing: "0.05em",
          },
        },
        colorSuccess: {
          backgroundColor: mint[10],
          color: secondary[130],
        },
        colorInfo: {
          backgroundColor: secondary[10],
          color: secondary[150],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#B04C14",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: fontSize.s,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          "&:not(.MuiBackdrop-invisible)": {
            background: "#00000090",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
          color: secondary[20],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: secondary[150],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: secondary[150],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: "#F8F7F6",
          color: secondary[150],
          fontWeight: 600,
          fontSize: fontSize.s,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#B0B2B9",
          "&.Mui-checked": {
            color: secondary[100],
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: 52,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: secondary[150],
            borderWidth: 1,
          },
          "&.MuiInputBase-multiline": {
            height: "auto",
          },
        },
        notchedOutline: {
          borderRadius: 12,
          border: `1px solid ${secondary[30]}`,
        },
        input: {
          padding: "14px 20px",
          color: secondary[150],
          fontSize: 17,
          fontWeight: 500,
          lineHeight: "140%",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          padding: 0,
          ".MuiOutlinedInput-root": {
            padding: 0,
            ".MuiAutocomplete-input": {
              padding: "0 20px",
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: secondary[130],
          fontSize: 14,
          fontWeight: 400,
          lineHeight: "140%",
        },
      },
    },
  },
});
