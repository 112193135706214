export const askAssembleSelectors = {
  history: "ask-assemble-history",
  inputForm: "ask-assemble-input-form",
  makeItSimpler: "ask-assemble-make-it-simpler",
  name: "ask-assemble-name",
  newChat: "ask-assemble-new-chat",
  emptyChat: "ask-assemble-empty-chat",
  promptLibraryBtn: "ask-assemble-promptLibraryBtn",
  prompt: "ask-assemble-prompt",
  question: "ask-assemble-question",
  response: "ask-assemble-response",
  submit: "ask-assemble-submit",
  tellMeMore: "ask-assemble-tell-me-more",
  topicSelector: "ask-assemble-topic-selector",
  shareBtn: "ask-assemble-shareBtn",
  openShareModalBtn: "ask-assemble-openShareModalBtn",
};

export const assembleUSelectors = {
  resource: "assemble-u-resource",
  toolkit: "assemble-u-toolkit",
};

export const confirmationPopupSelectors = {
  modal: "confirmation-popup-modal",
  confirm: "confirmation-popup-confirm",
};

export const dropdownMenuSelectors = {
  button: "dropdown-menu-button",
  menuItem: (item?: string) => (item ? `dropdown-menu-item-${item}` : "dropdown-menu-item"),
};

export const signupSelectors = {
  pageTitle: "register-page-title",
  password: "password-field",
  email: "email-field",
  passwordConfirmation: "password-confirmation-field",
  createAccountBtn: "create-account-btn",
};

export const pageSelectors = {
  title: "page-title",
};

export const tabsSelectors = {
  item: "tabs-item",
};

export const discoverSelectors = {
  featuredPost: "discover-featured-post",
  postCard: "discover-post-card",
  latestPosts: "discover-latest-posts",
  filterCategories: "discover-filter-categories",
  filterFormats: "discover-filter-formats",
  postCardCategory: "discover-post-card-category",
  postCardFormat: "discover-post-card-format",
};

export const signinSelectors = {
  loginBtn: "login-btn",
  password: "password-field",
  email: "email-field",
  submitBtn: "sign-in-btn",
};

export const dashboardSelectors = {
  poll: "poll",
  pollTitle: "poll-title",
  pollAnswer: "poll-user-answer",
  pollSubmitBtn: "submit-poll-btn",
  featuredPost: "featured-post",
  promptLibrary: "prompt-library",
  assembleU: "assemble-u",
  postTitle: "post-title",
};

export const communitySelectors = {
  liveSessions: "community-live-sessions",
  forums: "forums",
  latestForums: "latest-forums",
};

export const forumSelectors = {
  featuredCard: "forum-featured-card",
  card: "forum-card",
  cardCategory: "forum-card-category",
  comments: "forum-comments",
  title: "forum-title",
  description: "forum-description",
  commentForm: "forum-comment-form",
  commentFormSubmit: "forum-comment-form-submit",
  commentItem: "forum-comment-item",
  likeButton: "forum-like-button",
  header: "forum-header",
  replyComment: "forum-reply-comment",
  iconLikeEmpty: "FavoriteBorderOutlinedIcon",
  iconLikeFilled: "FavoriteOutlinedIcon",
};

export const liveSessionSelectors = {
  card: "live-session-card",
  empty: "live-sessions-empty",
  rsvp: "live-sessions-rsvp",
  cancel: "live-sessions-cancel",
  join: "live-sessions-join",
  openModal: "live-sessions-openModal",
};

export const listSelectors = {
  item: (item: string) => `list-item-${item}`,
};

export const onboardingSelectors = {
  givenName: "onboarding-givenName",
  familyName: "onboarding-familyName",
  name: "onboarding-name",
  phoneNumber: "onboarding-phoneNumber",
  phoneNumberRequestCode: "onboarding-phoneNumberRequestCode",
  smsCode: "onboarding-smsCode",
  smsCodeConfirm: "onboarding-smsCodeConfirm",
  phoneNumberVerified: "onboarding-phoneNumberVerified",
  country: "onboarding-country",
  state: "onboarding-state",
  city: "onboarding-city",
  role: "onboarding-role",
  otherRole: "onboarding-otherRole",
  yearsExperience: "onboarding-yearsExperience",
  hospitalDropdown: "onboarding-hospitalDropdown",
  hospital: "onboarding-hospital",
  nonHospitalCheckbox: "onboarding-nonHospitalCheckbox",
  nonHospitalPosition: "onboarding-nonHospitalPosition",
  nextStep: "onboarding-nextStep",
  prevStep: "onboarding-prevStep",
};

export const selectDropdownSelectors = {
  option: (value: string) => `selectDropdown-option-${value}`,
};

export const virtualizedAutocompleteSelectors = {
  option: (value?: string) => `virtualizedAutocomplete-option-${value}`,
};

export const unauthPromptSelectors = {
  title: "unauth-prompt-title",
};

export const askAssembleMarketingSelectors = {
  category: `askAssembleMarketing-category`,
  question: `askAssembleMarketing-question`,
  register: `askAssembleMarketing-register`,
};

export const nclexMarketingSelectors = {
  category: `nclexMarketing-category`,
  question: `nclexMarketing-question`,
};

export const nclexSelectors = {
  question: "nclex-question",
  answer: (option: number) => `nclex-answer-${option}`,
  submitBtn: "nclex-submitBtn",
  feedback: "nclex-feedback",
};

export const profileSelectors = {
  editMode: "edit-mode",
  viewMode: "view-mode",
  editProfileBtn: "edit-profile-btn",
  profileFieldGivenName: "profile-field-givenName",
  profileFieldFamilyName: "profile-field-familyName",
  profileFieldName: "profile-field-name",
  saveBtn: "save-btn",
  uploadImageBtn: "upload-image-btn",
  saveAvatarBtn: "save-avatar-btn",
};

export const creatorSelectors = {
  scheduleLiveBtn: "schedule-live-btn",
  assembleLiveTopic: "assemble-live-topic",
  showCalendarBtn: "show-calendar-btn",
  liveSessionSlot: (slot: string) => `live-session-slot-${slot}`,
  scheduleSessionsBtn: "schedule-sessions-btn",
  cancelLiveSessionBtn: "cancel-live-session",
  calendar: "calendar",
  liveSessionCard: "live-session-card",
};
