import { useSession } from "next-auth/react";

import type { User } from "next-auth";

import { getInitialsFromName } from "@helpers/profile";

export enum StatusType {
  "authenticated" = "authenticated",
  "unauthenticated" = "unauthenticated",
  "loading" = "loading",
}

export type UseAuthSession = User & {
  userId?: string;
  isAuthenticated: boolean;
  isUnauthenticated: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  hasAssembleU: boolean;
  initials: string;
};

const useAuthSession = (): UseAuthSession => {
  const { data, status } = useSession();

  const isLoading = status === StatusType.loading;
  const isAuthenticated = status === StatusType.authenticated;
  const isUnauthenticated = status === StatusType.unauthenticated;
  const isAdmin = data?.user?.email?.endsWith("comeassemble.com") ?? false;
  const initials = getInitialsFromName(data?.user?.name);
  const hasAssembleU = !!data?.user?.hospital?.isPaid;
  const user = data?.user as User;

  return {
    userId: data?.user?.id,
    isAuthenticated,
    isUnauthenticated,
    isLoading,
    isAdmin,
    initials,
    hasAssembleU,
    ...user,
  } satisfies UseAuthSession;
};

export default useAuthSession;
