import React, { Component, ErrorInfo, ReactNode } from "react";

import { Box, Button, Typography } from "@mui/material";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100dvh",
            flexDirection: "column",
            gap: "40px",
          }}
        >
          <Box sx={{ width: "200px" }} component="img" src="/images/app.logo.svg" alt="Logo" />

          <Typography variant="title" component="h1">
            Sorry.. there was an error!
          </Typography>

          <Button variant="contained" onClick={() => this.setState({ hasError: false })}>
            Try again?
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
