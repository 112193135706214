import { useAppSelector } from "@redux/store";

const GlobalPopup = () => {
  const { modal, component: Component, props } = useAppSelector(state => state.globalPopup);

  if (!modal || !Component) {
    return null;
  }

  return <Component {...props} />;
};

export default GlobalPopup;
