import Link from "next/link";
import { useRouter } from "next/router";

import { UserOnboardingStatus } from "@/types/userOnboardingStatus";
import { useAppContext } from "@context/AppContext";

import { BottomNavigation, Paper, Toolbar } from "@mui/material";

import { getLoggedInMenu } from "@configs/features/menus";

import useAuthSession from "@hooks/useAuthSession";

import { SBottomNavigationAction } from "./styles";

const MobileFooterNavbar = () => {
  const { hasAssembleU, isAuthenticated, status } = useAuthSession();
  const { pathname } = useRouter();
  const { isMobile } = useAppContext();

  const menu = getLoggedInMenu(hasAssembleU);

  if (!isMobile || !isAuthenticated || status !== UserOnboardingStatus.FINISHED) {
    return null;
  }

  return (
    <>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1200 }} elevation={3}>
        <BottomNavigation showLabels value={pathname} sx={{ height: 70 }}>
          {menu.map(({ name, path, icon: Icon, onClick }) => (
            <SBottomNavigationAction
              LinkComponent={Link}
              key={path}
              href={path}
              label={name}
              value={path}
              icon={<Icon />}
              onClick={onClick}
            />
          ))}
        </BottomNavigation>
      </Paper>
      <Toolbar />
    </>
  );
};

export default MobileFooterNavbar;
