import { FunctionComponent } from "react";

export enum ModalActionTypes {
  ShowModal = "showModal",
  HideModal = "hideModal",
}

export type ModalProps = {
  modal: boolean;
  component?: FunctionComponent;
  props?: {
    [key: string]: unknown;
  };
};

export type ModalAction = {
  type: ModalActionTypes;
  payload?: ModalProps;
};
