export const SEGMENT_EVENTS = {
  ABOUT_US: {
    CONTACT_MODAL_OPENED: "About Us Contact Modal Opened",
    CONTACT_MODAL_SUBMITTED: "About Us Contact Modal Submitted",
  },
  ASK_ASSEMBLE: {
    CONVERSATION_STARTED: "Ask Assemble Conversation Started",
    CONVERSATION_RENAMED: "Ask Assemble Conversation Renamed",
    CONVERSATION_ENDED: "Ask Assemble Conversation Ended",
    QUESTION_ASKED: "Ask Assemble Question Asked",
    QUESTION_RESPONDED: "Ask Assemble Question Responded",
    RESPONSE_LIKED: "Ask Assemble Response Liked",
    RESPONSE_DISLIKED: "Ask Assemble Response Disliked",
    CONVERSATION_SHARED: "Ask Assemble Conversation Shared",
    NEW_CHAT_CLICKED: "Ask Assemble New Chat Clicked",
    TELL_ME_MORE_CLICKED: "Tell Me More Clicked",
    MAKE_IT_SIMPLER_CLICKED: "Make It Simpler Clicked",
    PREVIOUS_CONVERSATION_SELECTED: "Previous Conversation Selected",
    CHAT_HISTORY_SHOWN: "Chat History Shown",
    CHAT_HISTORY_HIDDEN: "Chat History Hidden",
  },
  ASSEMBLE_LIVE: {
    CLICKED: "Assemble Live Clicked",
    RSVP_CLICKED: "Assemble Live RSVP Clicked",
    RSVP_CANCELED: "Assemble Live RSVP Canceled",
    JOINED: "Assemble Live Joined",
  },
  ASSEMBLE_U: {
    RESOURCE_OPENED: "Assemble U Resource Opened",
    RESOURCE_CLOSED: "Assemble U Resource Closed",
    TOOLKIT_OPENED: "Assemble U Toolkit Opened",
    TOOLKIT_CLOSED: "Assemble U Toolkit Closed",
    TOOLKIT_PAGE_CHANGED: "Assemble U Toolkit Page Changed",
    TOOLKIT_SPEAKER_NOTES_SHOWN: "Assemble U Toolkit Speaker Notes Shown",
    TOOLKIT_SPEAKER_NOTES_HIDDEN: "Assemble U Toolkit Speaker Notes Hidden",
  },
  BLOG: {
    POST_LIKED: "Blog Post Liked",
    POST_DISLIKED: "Blog Post Disliked",
    POST_BACK_BUTTON_CLICKED: "Blog Post Back Button Clicked",
  },
  DASHBOARD: {
    COMMUNITY_CLICKED: "Dashboard Community Clicked",
    FORUMS_CLICKED: "Dashboard Forums Clicked",
    ASSEMBLE_LIVE_CLICKED: "Dashboard Assemble Live Clicked",
    DISCOVER_CLICKED: "Dashboard Discover Clicked",
    VIDEOS_CLICKED: "Dashboard Videos Clicked",
    ARTICLES_CLICKED: "Dashboard Articles Clicked",
    ASSEMBLE_U_CLICKED: "Dashboard Assemble U Clicked",
    POLL_ANSWERED: "Poll Answered",
  },
  DISCOVER: {
    FEATURED_POST_CLICKED: "Featured Post Clicked",
    POST_CLICKED: "Post Clicked",
    POSTS_CATEGORY_CHANGED: "Posts Category Changed",
    POSTS_FORMAT_CHANGED: "Posts Format Changed",
    POSTS_PAGE_CHANGED: "Posts Page Changed",
    POST_LIKED: "Discover Post Liked",
    POST_DISLIKED: "Discover Post Disliked",
    POST_BACK_BUTTON_CLICKED: "Discover Post Back Button Clicked",
  },
  FORUM: {
    CARD_CLICKED: "Forum Card Clicked",
    TOPIC_SUGGEST_MODAL_OPENED: "Forum Topic Suggest Modal Opened",
    TOPIC_SUGGEST_MODAL_SUBMITTED: "Forum Topic Suggest Modal Submitted",
    CATEGORY_CLICKED: "Forum Category Clicked",
    PAGE_CHANGED: "Forum Page Changed",
    COMMENT_POSTED: "Forum Comment Posted",
    COMMENT_REPLIED: "Forum Comment Replied",
    LIKED: "Forum Liked",
    COMMENT_LIKED: "Forum Comment Liked",
    SHARE_MODAL_OPENED: "Forum Share Modal Opened",
    SHARE_LINK_COPIED: "Forum Share Link Copied",
    BACK_BUTTON_CLICKED: "Forum Back Button Clicked",
  },
  LOGGED_OUT_PROMPT: {
    SIGN_UP_WITH_GOOGLE_CLICKED: "Sign Up With Google From Logged Out Prompt Clicked",
    SIGN_UP_WITH_EMAIL_CLICKED: "Sign Up With Email From Logged Out Prompt Clicked",
    LOG_IN_FROM_LOGGED_OUT_PROMPT_CLICKED: "Log In From Logged Out Prompt Clicked",
  },
  NAVIGATION: {
    BAR_HOME_CLICKED: "Nav Bar Home Clicked",
    BAR_ASK_ASSEMBLE_CLICKED: "Nav Bar Ask Assemble Clicked",
    BAR_COMMUNITY_CLICKED: "Nav Bar Community Clicked",
    BAR_DISCOVER_CLICKED: "Nav Bar Discover Clicked",
    BAR_ASSEMBLE_U_CLICKED: "Nav Bar Assemble U Clicked",
    HELP_MODAL_OPENED: "Help Modal Opened",
    HELP_MODAL_SUBMITTED: "Help Modal Submitted",
    USER_MENU_OPENED: "User Menu Opened",
    USER_MENU_CREATOR_CLICKED: "User Menu Creator Clicked",
    USER_MENU_PROFILE_CLICKED: "User Menu Profile Clicked",
    USER_MENU_LOGOUT_CLICKED: "User Menu Logout Clicked",
    LOGIN_BUTTON_CLICKED: "Login Button Clicked",
    ASSEMBLE_LOGO_CLICKED: "Assemble Logo Clicked",
    FOOTER_ABOUT_US_CLICKED: "Footer About Us Clicked",
    FOOTER_TERMS_CONDITIONS_CLICKED: "Footer Terms & Conditions Clicked",
    FOOTER_PRIVACY_POLICY_CLICKED: "Footer Privacy Policy Clicked",
    FOOTER_SUPPORT_CLICKED: "Footer Support Clicked",
    FOOTER_BLOG_CLICKED: "Footer Blog Clicked",
  },
  ONBOARDING: {
    QUESTION_ANSWERED: "Onboarding Question Answered",
    FINISHED: "Onboarding Finished",
  },
  PROFILE: {
    IMAGE_UPLOADED: "Profile Image Uploaded",
    UPDATED: "Profile Updated",
  },
  PROMPT_LIBRARY: {
    CLICKED: "Prompt Clicked",
    CATEGORY_CLICKED: "Prompt Category Clicked",
    LIBRARY_LOAD_MORE_CLICKED: "Prompt Library Load More Clicked",
    LIBRARY_SHOW_LESS_CLICKED: "Prompt Library Show Less Clicked",
    LIBRARY_OPENED: "Prompt Library Opened",
    LIBRARY_CLOSED: "Prompt Library Closed",
  },
  REGISTER: {
    WITH_GOOGLE: "Register With Google Clicked",
    WITH_EMAIL: "Create Account Clicked",
  },
  ERROR: {
    HOME_BUTTON_CLICKED: "Error Home Button Clicked",
  },
};
