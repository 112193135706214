import type { FormValues, OnboardingStepsQuestions } from "@/types";
import type { User } from "next-auth";

export const CUSTOM_FIELDS = ["role", "interests", "hospital"];

export function getAnswers(userData: User, questions: OnboardingStepsQuestions[]): User {
  const user = { ...userData, hospital: userData.hospital?.id };

  return Object.entries(user).reduce((acc, [key, value]) => {
    const question = questions.find(({ name }) => name === key);

    if (!question) {
      return acc;
    }

    return { ...acc, [question.name]: getParsedValue(question?.questionType, value) };
  }, {}) as User;
}

function getParsedValue(type = "input", value: string) {
  if (type !== "checkbox") {
    return value;
  }

  if (!value) {
    return [];
  }

  const values = typeof value === "string" ? JSON.parse(value) : value;
  return values.map((value: string) => value);
}

export function getInitialsFromName(name?: string): string {
  return (
    name
      ?.match(/(\b\S)?/g)
      ?.join("")
      .match(/(^\S|\S$)?/g)
      ?.join("")
      .toUpperCase() ?? ""
  );
}

export function removeUnchangedAttributes(user: User, values: Partial<User>) {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = user[key as keyof User] !== value;
    const hasValue = Boolean(value);

    if (hasValue && hasChanged) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});
}

const analyticsAttributesMap: Record<string, string> = {
  city: "city",
  country: "country",
  state: "state",
  hospital: "hospital",
  nonHospitalPosition: "nonHospitalPosition",
  image: "image",
  role: "role",
  otherRole: "otherRole",
  yearsExperience: "yearsExperience",
  familyName: "lastName",
  givenName: "firstName",
  name: "preferredName",
  phoneNumber: "phoneNumber",
};

export const formatAttributesForAnalytics = (values: FormValues) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (analyticsAttributesMap[key]) {
      return { ...acc, [analyticsAttributesMap[key]]: value };
    }

    return acc;
  }, {});
};
