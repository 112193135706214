import { ReactNode } from "react";

import { useAppContext } from "@context/AppContext";

import { Box, Breakpoint, Container, ContainerProps } from "@mui/material";

import Footer from "@components/layout/Footer";
import Header from "@components/layout/Header";

import MobileFooterNavbar from "../MobileFooterNavbar";

type Indexable = {
  [key: string]: string | number | Indexable;
};

type LayoutProps = ContainerProps & {
  children: ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  maxWidth?: Breakpoint | false;
  header?: React.ComponentType;
  bodyStyle?: Indexable;
  headerStyle?: Indexable;
  footerStyle?: Indexable;
};

const Layout = ({
  children,
  header = Header,
  maxWidth = false,
  showHeader = true,
  showFooter = true,
  bodyStyle = {},
  headerStyle = {},
  footerStyle = {},
  disableGutters = false,
}: LayoutProps) => {
  const HeaderComponent = header;
  const { isMobile } = useAppContext();

  return (
    <Box>
      {showHeader && <HeaderComponent {...headerStyle} />}
      <Container
        component="main"
        maxWidth={maxWidth}
        disableGutters={disableGutters}
        {...bodyStyle}
      >
        {children}
      </Container>
      {showFooter && <Footer {...footerStyle} />}
      {isMobile && <MobileFooterNavbar />}
    </Box>
  );
};

export default Layout;
