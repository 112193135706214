import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SMenu = styled(Button)<{ component: unknown }>(
  ({ theme }) => `
  display: inline-flex;
  padding: 9px 20px;
  align-items: center;
  gap: 10px;
  transition: all 0.3s;
  min-height: 50px;
  font-size: ${theme.typography.pxToRem(14)};
  font-weight: 600;
  letter-spacing: -0.14px;
  border-radius: 53px;
  text-transform: none;

  &:hover {
    background: #E9E8E8;
  }

  &.active {
    background: #fff;

    svg {
      color: ${theme.palette.primary.main};
      --filled-color: ${theme.palette.primary.main};
    }
  }
`
);
