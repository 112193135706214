import { useRouter } from "next/router";
import { useEffect } from "react";

import { analytics } from "@libs/segment";
import NProgress from "nprogress";

NProgress.configure({ showSpinner: false });

const Analytics = () => {
  const { events } = useRouter();

  useEffect(() => {
    const handleStart = () => {
      NProgress.start();
      analytics.page();
    };

    const handleStop = () => {
      NProgress.done();
      analytics.page();
    };

    events.on("routeChangeStart", handleStart);
    events.on("routeChangeComplete", handleStop);
    events.on("routeChangeError", handleStop);

    return () => {
      events.off("routeChangeStart", handleStart);
      events.off("routeChangeComplete", handleStop);
      events.off("routeChangeError", handleStop);
    };
  }, [events]);

  return null;
};

export default Analytics;
