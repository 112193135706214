import { BottomNavigationAction } from "@mui/material";
import { styled } from "@mui/material/styles";

import { primary, secondary } from "@styles/colors";

export const SBottomNavigationAction = styled(BottomNavigationAction)<{ href: string }>`
  text-align: center;
  padding: 0;
  min-width: auto;
  flex: auto;

  .MuiBottomNavigationAction-label,
  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: ${({ theme }) => theme.typography.pxToRem(12.8)};
    color: ${secondary[150]};
  }

  .MuiBottomNavigationAction-label.Mui-selected {
    font-weight: 500;
    color: ${primary[100]};
  }
`;
