import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";

import { UserOnboardingStatus } from "@/types/userOnboardingStatus";

import { Button, Stack } from "@mui/material";

import useAuthSession from "@hooks/useAuthSession";

const UserMenu = dynamic(() => import("../UserMenu"));

const AuthUserControl = () => {
  const { pathname } = useRouter();
  const user = useAuthSession();

  if (user.status !== UserOnboardingStatus.FINISHED) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {pathname === "/" && (
        <Button variant="contained" component={Link} href="/dashboard">
          Access Assemble here
        </Button>
      )}
      <UserMenu {...user} />
    </Stack>
  );
};

export default AuthUserControl;
