import { ModalAction, ModalActionTypes, ModalProps } from "./actions";

export const initialState: ModalProps = {
  modal: false,
  component: undefined,
};

function modalReducer(state = initialState, action: ModalAction): ModalProps {
  switch (action.type) {
    case ModalActionTypes.ShowModal:
      return {
        ...state,
        modal: true,
        component: action.payload?.component,
        props: { ...action.payload?.props },
      };
    case ModalActionTypes.HideModal:
      return { ...initialState };
    default:
      return state;
  }
}

export default modalReducer;
