import { signOut } from "next-auth/react";
import type { ElementType } from "react";

import { SEGMENT_EVENTS } from "@/common/analytics";
import { UserType } from "@/types/userType";
import { track } from "@analytics/track";

import AskAssembleIcon from "public/images/menu/ask-assemble.svg";
import AssembleUIcon from "public/images/menu/assemble-u.svg";
import CommunityIcon from "public/images/menu/community.svg";
import DiscoverIcon from "public/images/menu/discover.svg";
import HomeIcon from "public/images/menu/home.svg";

export type UserMenuItem = {
  href: string;
  label: string;
  onClick: () => void;
  hideFor?: string[];
};
export const userMenu: UserMenuItem[] = [
  {
    href: "/creator",
    label: "Creator",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.USER_MENU_CREATOR_CLICKED),
    hideFor: [UserType.NURSE],
  },
  {
    href: "/profile",
    label: "Profile",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.USER_MENU_PROFILE_CLICKED),
  },
  {
    href: "/",
    label: "Logout",
    onClick: async () => {
      track(SEGMENT_EVENTS.NAVIGATION.USER_MENU_LOGOUT_CLICKED);
      await signOut({ redirect: true, callbackUrl: "/" });
    },
  },
];

export type LoggedInMenuItem = {
  name: string;
  path: string;
  icon: ElementType;
  onClick: () => void;
  hideIfMissingPaidHospital?: boolean;
};

const loggedInMenu: LoggedInMenuItem[] = [
  {
    icon: HomeIcon,
    name: "Home",
    path: "/dashboard",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.BAR_HOME_CLICKED),
  },
  {
    icon: AskAssembleIcon,
    name: "Ask Assemble",
    path: "/ask-assemble",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.BAR_ASK_ASSEMBLE_CLICKED),
  },
  {
    icon: CommunityIcon,
    name: "Community",
    path: "/community",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.BAR_COMMUNITY_CLICKED),
  },
  {
    icon: DiscoverIcon,
    name: "Discover",
    path: "/discover",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.BAR_DISCOVER_CLICKED),
  },
  {
    icon: AssembleUIcon,
    name: "Assemble U",
    path: "/assemble-u",
    onClick: () => track(SEGMENT_EVENTS.NAVIGATION.BAR_ASSEMBLE_U_CLICKED),
    hideIfMissingPaidHospital: true,
  },
];

export const getLoggedInMenu = (hasPaidHospital: boolean) => {
  return loggedInMenu.filter(item => !item.hideIfMissingPaidHospital || hasPaidHospital);
};
