import { useSession } from "next-auth/react";
import { ReactNode, createContext, useContext, useEffect } from "react";

import { analytics } from "@libs/segment";
import { Session } from "next-auth";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";

type AppContextType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const defaultAppContextValue: AppContextType = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const AppContext = createContext<AppContextType>(defaultAppContextValue);

type AppContextProviderProps = {
  children: ReactNode;
};

export const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const theme = useTheme();
  const { data } = useSession();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    const { user } = (data as Session) || {};

    if (user) {
      const { id, ...userData } = user;
      analytics.identify(id, userData);
    }
  }, [data]);

  return (
    <AppContext.Provider value={{ isMobile, isTablet, isDesktop }}>{children}</AppContext.Provider>
  );
};

export const useAppContext = () => useContext<AppContextType>(AppContext);
