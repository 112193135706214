import dynamic from "next/dynamic";
import Link from "next/link";
import type { FC } from "react";

import { SEGMENT_EVENTS } from "@/common/analytics";
import { track } from "@analytics/track";
import { useAppContext } from "@context/AppContext";

import { Container, IconButton, Stack, type StackProps } from "@mui/material";

import type { PopupSupportProps } from "@components/PopupSupport";

import usePopup from "@hooks/usePopup";

import InstagramLogo from "public/images/instagram.svg";
import LinkedInLogo from "public/images/linkedin.svg";

import { SCopyright, SFooter, SLink, SNav, SNavSection, SSocialLinks, STextButton } from "./styles";

const PopupSupport = dynamic<PopupSupportProps>(() => import("@components/PopupSupport"), {
  ssr: false,
}) as FC<PopupSupportProps>;

type FooterProps = StackProps;

const Footer = (props: FooterProps) => {
  const { isMobile } = useAppContext();
  const { handleOpenPopup } = usePopup();

  const handleOpenSupportModal = (type: string) => () => {
    track(SEGMENT_EVENTS.NAVIGATION.HELP_MODAL_OPENED);

    handleOpenPopup<PopupSupportProps>({
      component: PopupSupport,
      props: {
        title: type === "contact" ? "Contact us" : "Need help?",
        onSend: ({ message }) => track(SEGMENT_EVENTS.NAVIGATION.HELP_MODAL_SUBMITTED, { message }),
      },
    });
  };

  const handleTrackEvent = (event: string) => () => {
    track(event);
  };

  const renderSocialLinks = () => (
    <SSocialLinks>
      <IconButton component={Link} href="https://www.instagram.com/comeassemble/" sx={{ p: 0 }}>
        <InstagramLogo />
      </IconButton>
      <IconButton
        component={Link}
        href="https://www.linkedin.com/company/comeassemble"
        sx={{ p: 0 }}
      >
        <LinkedInLogo />
      </IconButton>
    </SSocialLinks>
  );

  return (
    <SFooter component="footer" {...props}>
      <Container>
        <Stack direction={{ xs: "column", md: "row" }} spacing={3} justifyContent="space-between">
          {isMobile && renderSocialLinks()}
          <SNav>
            <SNavSection>
              <SLink
                href="/blog"
                onClick={handleTrackEvent(SEGMENT_EVENTS.NAVIGATION.FOOTER_BLOG_CLICKED)}
              >
                Blog
              </SLink>
              <SLink
                href="/about-us"
                onClick={handleTrackEvent(SEGMENT_EVENTS.NAVIGATION.FOOTER_ABOUT_US_CLICKED)}
              >
                About us
              </SLink>
              <STextButton disableRipple onClick={handleOpenSupportModal("contact")}>
                Contact us
              </STextButton>
            </SNavSection>
            <SNavSection>
              <STextButton disableRipple onClick={handleOpenSupportModal("support")}>
                Support
              </STextButton>
              <SLink
                href="/privacy"
                onClick={handleTrackEvent(SEGMENT_EVENTS.NAVIGATION.FOOTER_PRIVACY_POLICY_CLICKED)}
              >
                Privacy
              </SLink>
              <SLink
                href="/terms"
                onClick={handleTrackEvent(
                  SEGMENT_EVENTS.NAVIGATION.FOOTER_TERMS_CONDITIONS_CLICKED
                )}
              >
                Terms & Conditions
              </SLink>
            </SNavSection>
          </SNav>

          <Stack direction="column" gap={3}>
            {!isMobile && renderSocialLinks()}
            <SCopyright>
              © {new Date().getFullYear()} Come Assemble, Inc. All rights reserved.
            </SCopyright>
          </Stack>
        </Stack>
      </Container>
    </SFooter>
  );
};

export default Footer;
