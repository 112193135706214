import Link from "next/link";
import React, { ElementType, memo } from "react";

import { SMenu } from "./styles";

type MenuProps = {
  children: string;
  href: string;
  active: boolean;
  icon: ElementType;
  onClick: () => void;
};

const MenuLink = ({ children, href, active = false, icon: Icon, onClick }: MenuProps) => {
  const className = active ? "active" : "";

  return (
    <SMenu component={Link} href={href} className={className} onClick={onClick}>
      <Icon />
      {children}
    </SMenu>
  );
};

export default memo(MenuLink);
