import { styled } from "@mui/material/styles";

export const SNavbar = styled("nav")`
  box-sizing: border-box;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  border-radius: 48px;
  border: 1px solid #f0f0f0;
  background: #f8f7f6;
  list-style: none;
  height: 60px;
  padding: 5px;
`;
