import { Analytics } from "@segment/analytics-node";
import type { TrackParams } from "@segment/analytics-node/";

import { addDebugLinkToProps } from "./util";

// From segment but not exported
type IdentityOptions =
  | { userId: string; anonymousId?: string }
  | { userId?: string; anonymousId: string };

const getAnalytics = () => {
  if (process.env.SEGMENT_NODE_WRITE_KEY) {
    return new Analytics({ writeKey: process.env.SEGMENT_NODE_WRITE_KEY });
  }
};

const track = (params: TrackParams) => {
  const _analytics = getAnalytics();
  if (_analytics) {
    _analytics.track(params);
  }
  return () => {
    /* noop */
  };
};

const ASK_ASSEMBLE_CONVERSATION_CREATED_FROM_SHARE = "Ask Assemble Conversation Created From Share";
type AskAssembleConversationCreatedFromShareProps = {
  is_logged_in: boolean;
  shared_conversation_id: string;
  conversation_id?: string;
};
export const trackAskAssembleConversationCreatedFromShare = (
  props: AskAssembleConversationCreatedFromShareProps,
  identity: IdentityOptions
) => {
  track({
    event: ASK_ASSEMBLE_CONVERSATION_CREATED_FROM_SHARE,
    properties: addDebugLinkToProps(props),
    ...identity,
  });
};
