import { AppBar, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SHeader = styled(AppBar)(
  ({ theme }) => `
  z-index: 1;
  box-shadow: none;
  padding: ${theme.spacing(3, 0)};
`
);

export const SToolbar = styled(Stack)(
  ({ theme }) => `
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(3)};

  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(0, 2)};
  }
`
);
